import React from 'react';

import styles from "./home.module.css"
import Line from "./line.svg"
import { Link } from 'react-router-dom';

export default function Home() {
    // Placeholder translations function

    return (
        <div className={styles.container}>
            <div className={`${styles.item} ${styles.a}`}>
                <div
                    style={{
                        display: 'flex',
                        flexDirection: 'row',
                        justifyContent: 'flex-start'
                    }}
                >
                    <p style={{
                        // font-extrabold text-4xl,
                        fontWeight: 'bolder',
                        fontSize: '2.25rem',
                        lineHeight: '2.5rem',
                        marginBottom: '0rem',
                    }}>
                        Trouve ton·ta futur·e colocataire à Bruxelles avec Cohive<br />
                    </p>
                    <img
                        src={process.env.PUBLIC_URL + "/files/spaghetti.svg"}
                        alt="spaghetti"
                        width={70}
                        height={36}
                        style={{
                            position: "relative",
                        }}
                    />
                </div>
                <p style={{ textAlign: "justify" }}>
                    Marre des galères pour trouver un·e bon·ne coloc ? Avec Cohive, trouvez quelqu'un qui matche avec votre vibe et votre style de vie.
                    <br />
                    <br />
                    ✓ Un matching basé sur tes attentes pour une colocation qui te ressemble.<br />
                    ✓ Des démarches simplifiées avec une gestion facile des contrats et documents.<br />
                    ✓ Une communauté engagée pour rencontrer d’autres colocations dans ton quartier.<br />
                    <br />
                    <br />
                    Cohive arrive en avril ! Inscris-toi sur la liste d’attente et suis-nous sur Instagram pour être parmi les premiers à tester l’application.
                </p>
                <p></p>
            </div>
            <div className={`${styles.item} ${styles.b} ${styles.centered}`}>
                <img src={process.env.PUBLIC_URL + "/files/image2.png"} alt="Missing pixel image" style={{ display: 'inline-block', marginLeft: '5px' }} height={"400px"} />
            </div>
            <div className={`${styles.item} ${styles.c}`}>
                <Link to="/contact" style={{ textDecoration: "none" }}>
                    <div className={styles.contactButton}>
                        <p style={{ textDecoration: "none" }}>
                            Reste informé
                            <img src={process.env.PUBLIC_URL + "/files/arrow.svg"} alt="missing-arrow" width={16} height={16} style={{ display: 'inline-block', marginLeft: '5px' }} />
                        </p>
                    </div>
                </Link>
            </div>
            <div className={`${styles.item} ${styles.d}`}>
                <p style={{ textAlign: "center" }}>
                    <img src={process.env.PUBLIC_URL + "/files/arrowRight.svg"} alt="arrow-right" width={16} height={16} style={{ display: 'inline-block', marginRight: '5px' }} />
                    Bientôt disponible sur <span className='font-bold'>google play</span> et <span className='font-bold'>App store</span>
                </p>
            </div>
        </div>
    )
}
