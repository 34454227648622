import React from 'react';
import './App.css';
import 'react-social-icons/instagram'
import 'react-social-icons/linkedin'
import {
  createBrowserRouter,
  Outlet,
  RouterProvider,
} from "react-router-dom";
import TermOfUse from './pages/termOfUse';
import Review from './pages/Review';
import DeleteData from './pages/deleteData';
import Home from './pages/home';
import Navbar from './component/navigation';

import "./App.css"
import ContactForm from './pages/contact';
import { SocialIcon } from 'react-social-icons';

const Wrapper = () => {

  return (
    <div
      style={{
        height: "100vh",
        justifyContent: 'space-between',
        display: "flex",
        flexDirection: "column",
      }}
    >
      <Navbar />
      <div className={"contentContainer"}>
        <Outlet />
      </div>
      <div>
        <footer className={"footer"}>
          <p>© 2025 Cohive</p>
          <div>
            <SocialIcon className='icons' url="https://www.instagram.com/cohive_app/" />
            <SocialIcon className='icons' url="https://www.linkedin.com/company/cohiveapp/" />
          </div>
        </footer>
      </div>
    </div>
  )
}


const Error404 = () => {

  return (
    <div
      style={{
        height: "100vh",
        justifyContent: 'space-between',
        display: "flex",
        flexDirection: "column",
      }}
    >
      <Navbar />
      <div className={"contentContainer"} style={{
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        alignItems: 'center',
      }}>
        <p
          style={{
            textAlign: "center",
            fontSize: "1.5rem",
            fontWeight: "bold",
          }}
        >
          This page does not exists.<br />
        </p>
        <img
          src={process.env.PUBLIC_URL + "/files/404.jpeg"}
          alt='Cute bee dat does not know where to go'
          width={400}
          height={400}
          style={{
            borderRadius: "50%",
          }}
        />
        <p>
          You can check our social networks down here.
        </p>
      </div>
      <div>
        <footer className={"footer"}>
          <p>© 2024 Cohive</p>
          <div>
            <SocialIcon className='icons' url="https://www.instagram.com/cohive_app/" />
            <SocialIcon className='icons' url="https://www.linkedin.com/company/cohiveapp/" />
          </div>
        </footer>
      </div>
    </div>
  )
}

const router = createBrowserRouter([
  {
    element: <Wrapper />,
    errorElement: <Error404 />,
    children: [
      {
        path: "/",
        element: <Home />
      },
      {
        path: "/contact",
        element: <ContactForm />,
      },
      {
        path: "/termOfUse",
        element: <TermOfUse />,
      },
      {
        path: "/review",
        element: <Review />,
      },
      {
        path: '/deleteData',
        element: <DeleteData />,
      }
    ]
  }
]);




function App() {
  return (
    <React.StrictMode>
      <RouterProvider router={router} />

    </React.StrictMode>
  )
};

//   );
// }

export default App;
